



























import {BCard, BCardBody, BCardHeader, BCardTitle} from "bootstrap-vue";
import chartjsData from "@/components/charts/chartjsData";
import {$themeColors} from "@themeConfig";
import OwnersService from "@/services/OwnersService";
import helperService from "@/services/HelperService";
import ChartjsComponentHorizontalBarChart
  from "@/components/charts/charts-components/ChartjsComponentHorizontalBarChart.vue";

export default {
  components: {ChartjsComponentHorizontalBarChart, BCardHeader, BCardTitle, BCard, BCardBody},
  props: {
    uid: {
      required: false,
      type: String,
      default: '',
    },
    groupUid: {
      required: false,
      type: String,
      default: '',
    },
    from: {
      required: false,
      type: String,
      default: '',
    },
    to: {
      required: false,
      type: String,
      default: '',
    }
  },
  data() {
    return {
      chartDataReady: false,
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: $themeColors.primary,
            borderColor: 'transparent',
          },
        ],
      },
      loading: true,
      chartjsData,
      noData: false,
      filters: {
        page: 0,
        page_size: 10,
        search: '',
        app: '',
        from: new Date(new Date().setDate(new Date().getDate() - 7)).toDateString(),
        to: new Date().toDateString(),
        owner_uid: '',
        group_uid: '',
        unique: '',
      },
      maxTags: 10,
    }
  },
  watch: {
    from: function() {
      this.filters.from = this.from;
      this.filters.to = this.to;
      this.getTopTags()
    },
    to: function() {
      this.filters.from = this.from;
      this.filters.to = this.to;
      this.getTopTags()
    },
  },
  mounted() {
    if (this.uid !== '') {
      this.filters.owner_uid = this.uid
    }

    if (this.groupUid !== '') {
      this.filters.group_uid = this.groupUid
    }

    if (this.from !== '') {
      this.filters.from = this.from;
    }
    if (this.to !== '') {
      this.filters.to = this.to;
    }

    if (this.unique !== '') {
      this.filters.unique = this.unique;
    }

    this.getTopTags();
  },
  methods: {
    async getTopTags() {
      this.loading = true
      OwnersService.getTopTags(this.filters).then(res => {
        // CLear the arrays
        this.chartData.labels = [];
        this.chartData.datasets[0].data = [];
        for (let i=0; i<Math.min(res.data.data.length, this.maxTags); i++) {
          this.chartData.labels.push(res.data.data[i].tag)
          this.chartData.datasets[0].data.push(res.data.data[i].count)
        }
        this.noData = res.data.data.length === 0
        this.chartDataReady = true
      }).catch(err => {
        helperService.showNotfyErr(this.$toast, err, 'Could not get tags, please refresh and try again')
      })
      .finally(() => {
        this.loading = false
      });
    },
  }
}
